<template>
  <TitleCoaching title="Gérer mes indisponibilités" />
  <DaysOff />
</template>

<script>
import TitleCoaching from "@/components/Coaching/TitleCoaching";
import DaysOff from "@/components/Coaching/DaysOff";
export default {
  name: "DaysOffView",
  components: {DaysOff, TitleCoaching}
}
</script>

<style scoped>

</style>