import {default as apiAxiosInstance} from "@/services/axios/api.axios";
import apiHelper from "@/helpers/api";

class DaysOffService {
    getDaysOff() {
        return apiAxiosInstance.get(apiHelper.daysOffEndpoint);
    }
    deleteDaysOff(id) {
        return apiAxiosInstance.delete(apiHelper.daysOffDeleteEndpoint.replace('{id}', id));
    }
    addDaysOff(startsAt, endsAt) {
        let params = {
            'starts_at': startsAt,
            'ends_at': endsAt
        }
        return apiAxiosInstance.post(apiHelper.daysOffEndpoint, params);
    }
}

export default new DaysOffService();