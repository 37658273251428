<template>
  <div class="time-box" v-for="(dayOff) in daysOff" :key="dayOff.id">
    Du {{formatDate(dayOff.starts_at)}} au {{formatDate(dayOff.ends_at)}}
    <button @click="removeDayOff(dayOff.id)">
      <svg width="12" height="13" viewBox="0 0 12 13" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.27455 6.5L11.686 3.17736C12.1047 2.76963 12.1047 2.10855 11.686 1.70049L10.9278 0.962051C10.5092 0.554316 9.83046 0.554316 9.41148 0.962051L6 4.28469L2.58852 0.962051C2.16989 0.554316 1.49114 0.554316 1.07216 0.962051L0.313977 1.70049C-0.104659 2.10822 -0.104659 2.7693 0.313977 3.17736L3.72545 6.5L0.313977 9.82264C-0.104659 10.2304 -0.104659 10.8914 0.313977 11.2995L1.07216 12.0379C1.4908 12.4457 2.16989 12.4457 2.58852 12.0379L6 8.71531L9.41148 12.0379C9.83011 12.4457 10.5092 12.4457 10.9278 12.0379L11.686 11.2995C12.1047 10.8918 12.1047 10.2307 11.686 9.82264L8.27455 6.5Z" />
      </svg>
    </button>
  </div>
  <form class="availabity-form" v-on:submit.prevent="submitDayOff">
    <div class="container">
      <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
          <path d="M5.94643 12.375H4.33929C4.07411 12.375 3.85714 12.143 3.85714 11.8594V10.1406C3.85714 9.85703 4.07411 9.625 4.33929 9.625H5.94643C6.21161 9.625 6.42857 9.85703 6.42857 10.1406V11.8594C6.42857 12.143 6.21161 12.375 5.94643 12.375ZM10.2857 11.8594V10.1406C10.2857 9.85703 10.0687 9.625 9.80357 9.625H8.19643C7.93125 9.625 7.71429 9.85703 7.71429 10.1406V11.8594C7.71429 12.143 7.93125 12.375 8.19643 12.375H9.80357C10.0687 12.375 10.2857 12.143 10.2857 11.8594ZM14.1429 11.8594V10.1406C14.1429 9.85703 13.9259 9.625 13.6607 9.625H12.0536C11.7884 9.625 11.5714 9.85703 11.5714 10.1406V11.8594C11.5714 12.143 11.7884 12.375 12.0536 12.375H13.6607C13.9259 12.375 14.1429 12.143 14.1429 11.8594ZM10.2857 15.9844V14.2656C10.2857 13.982 10.0687 13.75 9.80357 13.75H8.19643C7.93125 13.75 7.71429 13.982 7.71429 14.2656V15.9844C7.71429 16.268 7.93125 16.5 8.19643 16.5H9.80357C10.0687 16.5 10.2857 16.268 10.2857 15.9844ZM6.42857 15.9844V14.2656C6.42857 13.982 6.21161 13.75 5.94643 13.75H4.33929C4.07411 13.75 3.85714 13.982 3.85714 14.2656V15.9844C3.85714 16.268 4.07411 16.5 4.33929 16.5H5.94643C6.21161 16.5 6.42857 16.268 6.42857 15.9844ZM14.1429 15.9844V14.2656C14.1429 13.982 13.9259 13.75 13.6607 13.75H12.0536C11.7884 13.75 11.5714 13.982 11.5714 14.2656V15.9844C11.5714 16.268 11.7884 16.5 12.0536 16.5H13.6607C13.9259 16.5 14.1429 16.268 14.1429 15.9844ZM18 4.8125V19.9375C18 21.0762 17.1362 22 16.0714 22H1.92857C0.863839 22 0 21.0762 0 19.9375V4.8125C0 3.67383 0.863839 2.75 1.92857 2.75H3.85714V0.515625C3.85714 0.232031 4.07411 0 4.33929 0H5.94643C6.21161 0 6.42857 0.232031 6.42857 0.515625V2.75H11.5714V0.515625C11.5714 0.232031 11.7884 0 12.0536 0H13.6607C13.9259 0 14.1429 0.232031 14.1429 0.515625V2.75H16.0714C17.1362 2.75 18 3.67383 18 4.8125ZM16.0714 19.6797V6.875H1.92857V19.6797C1.92857 19.8215 2.03705 19.9375 2.16964 19.9375H15.8304C15.9629 19.9375 16.0714 19.8215 16.0714 19.6797Z" fill="#1A77FF" stroke="white"/>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="18" height="22" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      <vaadin-date-time-picker :value="selectedDateStart" @change="selectedDateStart = $event.target.value"></vaadin-date-time-picker>
      <svg width="12" height="4" viewBox="0 0 12 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="12" height="4" rx="2" fill="#A8AFBC"/>
      </svg>
      <vaadin-date-time-picker :value="selectedDateEnd" @change="selectedDateEnd = $event.target.value"></vaadin-date-time-picker>

      <button class="btn-pink">OK</button>
    </div>
  </form>
</template>

<script>
import moment from "moment";
import '@vaadin/vaadin-date-time-picker';
import daysOffService from "@/services/daysOff.service";
import Toastify from "toastify-js";

export default {
  name: "DaysOff",
  data() {
    return {
      selectedDateStart: "",
      selectedDateEnd: "",
      daysOff: []
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format('dddd, DD MMMM - HH:mm')
    },
    submitDayOff() {
      if(this.selectedDateStart && this.selectedDateEnd) {
        daysOffService.addDaysOff(moment(this.selectedDateStart).format(), moment(this.selectedDateEnd).format())
            .then((response) => {
              this.daysOff.push(response.data.data.day_off)
              this.selectedDateStart = this.selectedDateEnd = ''
            })
            .catch((err) => {
              for (var prop in err.response.data.data) {
                Toastify({
                  text: err.response.data.data[prop],
                  className: "error",
                }).showToast();
              }
            })
      } else {
        if(!this.selectedDateStart) {
          Toastify({
            text: 'Merci de sélectionner un jour de début',
            className: "error",
          }).showToast();
        }
        if(!this.selectedDateEnd) {
          Toastify({
            text: 'Merci de sélectionner un jour de fin',
            className: "error",
          }).showToast();
        }
      }
    },
    removeDayOff(id) {
      daysOffService.deleteDaysOff(id)
          .then(() => {
            let index = this.daysOff.findIndex(x => x.id === id)
            this.daysOff.splice(index, 1);
          });
    }
  },
  mounted() {
    daysOffService.getDaysOff()
        .then((response) => {
          this.daysOff = response.data.data.days_off;
        });

    customElements.whenDefined('vaadin-date-time-picker').then(function() {
      const dateTimePickers = document.querySelectorAll('vaadin-date-time-picker');

      const i18n = {
        today: 'aujourd\'hui',
        cancel: 'Annuler',
        firstDayOfWeek: 1,
        monthNames: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
        weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
        weekdaysShort: 'di_lu_ma_me_je_ve_sa'.split('_'),
        formatDate: function(dateObject) {
          const date = moment(dateObject);
          return date.format('DD/MM/YYYY');
        },
        parseDate: function(dateString) {
          const date = moment(dateString, 'DD/MM/YYYY');
          return {
            day: parseInt(date.format('DD')),
            month: parseInt(date.format('MM') - 1),
            year: parseInt(date.format('YYYY'))
          };
        }
      };

      dateTimePickers.forEach(function(dateTimePicker) {
        dateTimePicker.i18n = Object.assign({}, dateTimePicker.i18n, i18n);
      });
    });
  }
}
</script>

<style scoped>

</style>